import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeDeAt from '@angular/common/locales/de-AT';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEnIe from '@angular/common/locales/en-IE';
import localeEnHi from '@angular/common/locales/en-IN';
import localeEnMy from '@angular/common/locales/en-MY';
import localeEnNz from '@angular/common/locales/en-NZ';
import localeEnSg from '@angular/common/locales/en-SG';
import localeEs from '@angular/common/locales/es';
import localeEsMx from '@angular/common/locales/es-MX';
import localeEt from '@angular/common/locales/et';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHi from '@angular/common/locales/hi';
import localeId from '@angular/common/locales/id';
import localeIs from '@angular/common/locales/is';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeko from '@angular/common/locales/ko';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeMs from '@angular/common/locales/ms';
import localeNb from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localeNlBe from '@angular/common/locales/nl-BE';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeSv from '@angular/common/locales/sv';
import localeTh from '@angular/common/locales/th';
import localeVi from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';
import localeZhSg from '@angular/common/locales/zh-Hans-SG';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { GoogleMapsModule } from '@angular/google-maps'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { Angulartics2Module } from 'angulartics2';
import { CoreButtonModule } from 'app/core/button/CoreButtonModule';
import { ContactUsModule } from 'app/core/contact-us/ContactUsModule';
import { CoreContentModule } from 'app/core/content/CoreContentModule';
import { LoadingModule } from 'app/core/loading/LoadingModule';
import { CoreLocaleModule } from 'app/core/locale/CoreLocaleModule';
import { CoreMegaSearchModule } from 'app/core/mega-search/CoreMegaSearchModule';
import { RailMapModule } from 'app/core/rail-map/RailMapModule';
import { CoreSiteFooterModule } from 'app/core/site-footer/CoreSiteFooterModule';
import { CoreTaskSummaryModule } from 'app/core/task-summary/CoreTaskSummaryModule';
import { ShipmentMenuModule } from 'app/shipment/menu/ShipmentMenuModule';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from '../environments/environment';
import { AdminMenuModule } from "./admin/menu/AdminMenuModule";
import { AppComponent } from './AppComponent';
import { BookingCargoGroupsModule } from './booking/cargo-groups/BookingCargoGroupsModule';
import { AlertModule } from './core/alert/alert.module';
import { AlertService } from './core/alert/alert.service';
import { CommonMaterialModule } from './core/common-material/core-common-material.module';
import { CoreModule } from './core/CoreModule';
import { CoreCountryModule } from './core/country/CoreCountryModule';
import { GoToModule } from './core/goto/goto.module';
import { LocaleSelectModule } from './core/locale/locale-select/LocaleSelectModule';
import { CoreMissingTranslationHandler } from './core/translate/CoreMissingTranslationHandler';
import { CoreTranslateLoader } from './core/translate/CoreTranslateLoader';
import { DefaultComponent } from './DefaultComponent';
import { PushSubscriptionHandlerModule } from './generated/backend/comm/service/push-subscription-handler';
import { GlossaryTagModule } from './glossary/GlossaryTagModule';
import { HomeModule } from './home/HomeModule';
import { PartyMenuModule } from './party/menu/PartyMenuModule';
import { PublicMenuModule } from './public/menu/PublicMenuModule';
import { UserMenuModule } from './user/menu/UserMenuModule';


registerLocaleData(localeDa);
registerLocaleData(localeDe);
registerLocaleData(localeDeAt);
registerLocaleData(localeEnGb);
registerLocaleData(localeEnHi);
registerLocaleData(localeEnIe);
registerLocaleData(localeEnMy);
registerLocaleData(localeEnNz);
registerLocaleData(localeEnSg);
registerLocaleData(localeEs);
registerLocaleData(localeEsMx);
registerLocaleData(localeEt);
registerLocaleData(localeFi);
registerLocaleData(localeFr);
registerLocaleData(localeHi);
registerLocaleData(localeId);
registerLocaleData(localeIs);
registerLocaleData(localeko);
registerLocaleData(localeJa);
registerLocaleData(localeIt);
registerLocaleData(localeLt);
registerLocaleData(localeLv);
registerLocaleData(localeMs);
registerLocaleData(localeNb);
registerLocaleData(localeNl);
registerLocaleData(localeNlBe);
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeRu);
registerLocaleData(localeSv);
registerLocaleData(localeTh);
registerLocaleData(localeVi);
registerLocaleData(localeZh);
registerLocaleData(localeZhSg);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const appRoutes: Routes = [
	{ path: '', loadChildren: () => import('./home/HomeModule').then(m => m.HomeModule) },
	{ path: 'booking', loadChildren: () => import('./booking/BookingModule').then(m => m.BookingModule) },
	{ path: 'public', loadChildren: () => import('./public/public.module').then(m => m.PublicModule) },
	{ path: 'glossary', loadChildren: () => import('./glossary/GlossaryModule').then(m => m.GlossaryModule) },
	{ path: 'shipment', loadChildren: () => import('./shipment/ShipmentModule').then(m => m.ShipmentModule) },
	{ path: 'user', loadChildren: () => import('./user/UserModule').then(m => m.UserModule) },
	{ path: 'party', loadChildren: () => import('./party/PartyModule').then(m => m.PartyModule) },
	{ path: 'admin', loadChildren: () => import('./admin/AdminModule').then(m => m.AdminModule) },
	{ path: 'world', loadChildren: () => import('./world/WorldModule').then(m => m.WorldModule) },
	{ path: 'test', loadChildren: () => import('./test/test.module').then(m => m.TestModule) },
	{ path: '**', component: DefaultComponent }
];

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_NATIVE_DATE_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'DD MMM YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	declarations: [
		AppComponent,
		DefaultComponent
	],
	imports: [
		AdminMenuModule,
		AlertModule,
		AlertModule,
		BookingCargoGroupsModule,
		BrowserAnimationsModule,
		BrowserModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		CommonMaterialModule,
		ContactUsModule,
		CoreButtonModule,
		CoreContentModule,
		CoreCountryModule,
		CoreMegaSearchModule,
		CoreLocaleModule,
		CoreModule,
		CoreSiteFooterModule,
		CoreTaskSummaryModule,
		FormsModule,
		GoogleMapsModule,
		GoToModule,
		GlossaryTagModule,
		HomeModule,
		HttpClientModule,
		LoadingModule,
		LocaleSelectModule,
		MatNativeDateModule,
		MatToolbarModule,
		NgbModule,
		NgxPaginationModule,
		NgxWebstorageModule.forRoot(),
		PartyMenuModule,
		PublicMenuModule,
		PushSubscriptionHandlerModule,
		ShipmentMenuModule,
		RailMapModule,
		UserMenuModule,
		RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CoreTranslateLoader,
				deps: [HttpClient]
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: CoreMissingTranslationHandler
			}
		}),
		Angulartics2Module.forRoot({
			pageTracking: {
				clearIds: true,
				clearHash: true
			}
		}),
		ServiceWorkerModule.register('ngsw-custom.js', { enabled: environment.production })
	],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: MY_NATIVE_DATE_FORMATS },
		AlertService],
	bootstrap: [AppComponent]
})
export class AppModule { }

import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShipmentContainerOperationTimeSlotModel } from 'app/generated/backend/shipment/api/shipment-container-operation-time-slot-model';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';
import { ShipmentLocationType } from 'app/generated/backend/types/shipment-location-type';

@Injectable()
export class ShipmentContainerOperationTimeSlotHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
		shipmentNumber: string,
		containerId: number,
		partyPlacePointId: number,
		locationType: ShipmentLocationType
	): Observable<JsonResourceResponse<ShipmentContainerOperationTimeSlotModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (shipmentNumber!==null && shipmentNumber!==undefined) {
			params = params.set('shipmentNumber', shipmentNumber);
		}
		if (containerId!==null && containerId!==undefined) {
			params = params.set('containerId', containerId.toString());
		}
		if (partyPlacePointId!==null && partyPlacePointId!==undefined) {
			params = params.set('partyPlacePointId', partyPlacePointId.toString());
		}
		if (locationType!==null && locationType!==undefined) {
			params = params.set('locationType', locationType.toString());
		}
		return this.http.get<JsonResourceResponse<ShipmentContainerOperationTimeSlotModel>>('api/v1/shipment/operationTimeSlot', { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	put(
		shipmentNumber: string,
		partyPlacePointId: number,
		locationType: ShipmentLocationType,
		request: ShipmentContainerOperationTimeSlotModel
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (shipmentNumber!==null && shipmentNumber!==undefined) {
			params = params.set('shipmentNumber', shipmentNumber);
		}
		if (partyPlacePointId!==null && partyPlacePointId!==undefined) {
			params = params.set('partyPlacePointId', partyPlacePointId.toString());
		}
		if (locationType!==null && locationType!==undefined) {
			params = params.set('locationType', locationType.toString());
		}
		return this.http.put<JsonResourceResponse<ShipmentDetailModel>>('api/v1/shipment/operationTimeSlot', request, { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		ShipmentContainerOperationTimeSlotHandlerService
	]
})
export class ShipmentContainerOperationTimeSlotHandlerModule { }

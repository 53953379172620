import { ContainerEquipment } from 'app/generated/backend/types/container-equipment';
import { LocalityLookupModel } from 'app/generated/backend/types/locality-lookup-model';
import { OperatingHours } from 'app/generated/backend/types/operating-hours';
import { PartyPlacePointDetailModel } from 'app/generated/backend/yard/api/party-place-point-detail-model';
export class PartyPlaceDetailModel {
	id: number;
	updatedUserId: number;
	updatedAt: string;
	partyId: number;
	code: string;
	name: string;
	countryId: number;
	postalCode: string;
	intlAddress: string;
	localAddress: string;
	mapAddress: string;
	latitude: number;
	longitude: number;
	autoSelect: boolean;
	isStoreLocation: boolean;
	partyOperatingHours: OperatingHours;
	placeOperatingHours: OperatingHours;
	equipment: ContainerEquipment;
	instructions: string;
	partyPlacePoints: PartyPlacePointDetailModel[];
	nearestLocality: LocalityLookupModel;
	defaultDeliveryDuration: number;
	countryPublicHolidays: string[];
}

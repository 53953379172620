import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreMapMarkerComponent } from './CoreMapMarkerComponent';
import { CoreMapComponent } from './CoreMapComponent';
import { CoreMapPolylineComponent } from './CoreMapPolylineComponent';
import { CoreMapPolylinePointComponent } from './CoreMapPolylinePointComponent';
import { AbmModule } from 'angular-baidu-maps';
import { CoreMapService } from './CoreMapService';
import { CoreTileMapComponent } from './tiles/CoreTileMapComponent';
import { CoreStaticMapComponent } from './tiles/CoreStaticMapComponent';
import { CoreTileMapMarkerComponent } from './tiles/CoreTileMapMarkerComponent';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
	imports: [
		AbmModule.forRoot({ apiKey: 'xOy5bK4UqQ75h1p4POmAy534YXkkTCGo' }),
		CommonModule,
		GoogleMapsModule
	],
	exports: [
		CoreMapComponent,
		CoreMapMarkerComponent,
		CoreMapPolylineComponent,
		CoreMapPolylinePointComponent,
		CoreTileMapComponent,
		CoreStaticMapComponent,
		CoreTileMapMarkerComponent],
	declarations: [
		CoreMapComponent,
		CoreMapMarkerComponent,
		CoreMapPolylineComponent,
		CoreMapPolylinePointComponent,
		CoreTileMapComponent,
		CoreStaticMapComponent,
		CoreTileMapMarkerComponent],
	providers: [CoreMapService]
})
export class CoreMapModule { }

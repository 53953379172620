import { DatePipe } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CoreLocaleLocalizationService } from 'app/core/locale/CoreLocaleLocalizationService';
import { LocaleLookupModel } from 'app/generated/backend/localization/api/locale-lookup-model';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Subscription } from 'rxjs';
import { CoreCustomizationService } from '../customization/CoreCustomizationService';
import { CustomizationSettings } from '../customization/CustomizationSettings';


const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreDateInputComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreDateInputComponent),
	multi: true
};

@Component({
	selector: 'app-date-input',
	templateUrl: './CoreDateInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreDateInputComponent implements OnInit, OnDestroy, Validator {

	@Input()
	required: boolean;

	@Input()
	placeholder: string = '';

	@Input()
	label: string;

	@Input()
	helpUri: string;

	@Input()
	disabled = false;

	@Input()
	iconClass: string;

	@Input()
	hasTime: boolean = false;

	@Input()
	inActiveFrom: string = '';

	@Input()
	inActiveBefore: string = '';

	@Input()
	countryPublicHolidays: string[] = [];

	@Output()
	onChange = new EventEmitter<string>();

	private _min: string;
	@Input()
	public set min(value: string) {
		if (this._min !== value) {
			this._min = value;
			if (!value) {
				this.minDateModel = null;
			} else {
				this.minDateModel = this.isoToNgbDate(value);
			}
		}
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	private _max: string;
	@Input()
	public set max(value: string) {
		if (this._max !== value) {
			this._max = value;
			if (!value) {
				this.maxDateModel = null;
			} else {
				this.maxDateModel = this.isoToNgbDate(value);
			}
		}
		this.validate(null);
		this.onChangeCallback(this._innerValue);
	}

	public errorStateMatcher = new SimpleErrorStateMatcher();

	private subscriptions = new Array<Subscription>();
	private _innerValue: string;

	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	public datePickerModel: NgbDateStruct;
	public maxDateModel: NgbDateStruct;
	public minDateModel: NgbDateStruct;
	public inputModel: string;

	public showCalendar: boolean = false;
	private justToggled: boolean = false;
	private datePipe: DatePipe;
	private locale: LocaleLookupModel;
	private dateTimeParseError: string;

	onInputChanged(value: string) {
		if (value) {
			try {
				const isoDateString = this.coreLocaleLocalizationService.localizedToIsoDateString(value, this.hasTime, this.locale);
				if (isoDateString !== this._innerValue || this.dateTimeParseError) {
					this._innerValue = isoDateString;
					this.dateTimeParseError = null;
					this.validate(null);
					this.onChangeCallback(this._innerValue);
					this.onChange.emit(this._innerValue);
					this.inputModel = this.isoToLocalized(this._innerValue);
				}
			} catch (error) {
				if (error) {
					console.error(error);
					this.dateTimeParseError = error;
					this.validate(null);
					this.onChangeCallback(this._innerValue);
					this.onChange.emit(this._innerValue);
				}
			}
		} else {
			this.onClear();
		}

	}

	onClear() {
		this._innerValue = null;
		this.dateTimeParseError = null;
		this.showCalendar = false;
		this.datePickerModel = null;
		this.inputModel = null;
		this.validate(null);
		this.onChangeCallback(this._innerValue);
		this.onChange.emit(this._innerValue);
	}

	clickOut() {
		if (!this.justToggled) {
			this.showCalendar = false;
		}
		this.justToggled = false;
	}

	toggleCalendar() {
		this.justToggled = true;
		if (this.showCalendar) {
			this.showCalendar = false;
		} else {
			this.showCalendar = true;
			this.datePickerModel = this.isoToNgbDate(this._innerValue);
		}
	}

	isoToLocalized(value: string): string {
		if (value) {
			try {
				const locale = this.customizationService.getLocale();
				if (this.hasTime) {
					return this.datePipe.transform(value, locale.dateTimeFormat);
				} else {
					return this.datePipe.transform(value, locale.dateFormat);
				}
			}
			catch (error) {
				return null;
			}
		}
		return null;
	}

	isoToNgbDate(value: string): NgbDateStruct {
		if (value && value.length >= 10) {
			return {
				year: parseInt(value.substr(0, 4), 10),
				month: parseInt(value.substr(5, 2), 10),
				day: parseInt(value.substr(8, 2), 10)
			};
		}
		return null;
	}

	onDateSelect(event: NgbDate) {
		if (event) {
			let str = '';
			str += event.year.toString();
			str += '-';
			if (event.month < 10) {
				str += '0';
			}
			str += event.month.toString();
			str += '-';
			if (event.day < 10) {
				str += '0';
			}
			str += event.day.toString();
			if (this.hasTime) {
				if (this._innerValue) {
					str += this._innerValue.substr(10, 9);
				} else {
					str += 'T00:00:00';
				}
			}
			if (str !== this._innerValue) {
				this.inputModel = this.isoToLocalized(str);
				this.dateTimeParseError = null;
				this._innerValue = str;
				this.onChangeCallback(this._innerValue);
				this.onChange.emit(this._innerValue);
				this.validate(null);
			}
			this.showCalendar = false;
		}
	}


	public validate(c: UntypedFormControl): ValidationErrors | null {
		if (this.dateTimeParseError) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = this.dateTimeParseError;
			return {
				invalid: true
			};
		}
		if (this._innerValue) {
			if (this._min && this._innerValue < this._min) {
				this.errorStateMatcher.valid = false;
				this.errorStateMatcher.errorKey = 'error.invalidDate';
				return {
					invalid: true
				};
			}
			if (this._max && this._innerValue < this._max) {
				this.errorStateMatcher.valid = false;
				this.errorStateMatcher.errorKey = 'error.invalidDate';
				return {
					invalid: true
				};
			}
			if (this.inActiveFrom || this.inActiveBefore) {
				const ngbDateStruct = this.isoToNgbDate(this._innerValue);
				if (this.isWithinInactivePeriod(new NgbDate(ngbDateStruct.year, ngbDateStruct.month, ngbDateStruct.day))) {
					this.errorStateMatcher.valid = false;
					this.errorStateMatcher.errorKey = 'error.invalidDate';
					return {
						invalid: true
					};
				}
			}
		} else if (this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				required: true
			};
		}
		this.errorStateMatcher.valid = true;
		return null;
	}

	writeValue(value: string) {
		if (value) {
			if (this.hasTime) {
				value = value.substr(0, 19);
			} else {
				value = value.substr(0, 10);
			}
		}
		if (value !== this._innerValue) {
			this._innerValue = value;
			this.dateTimeParseError = null;
			if (this._innerValue) {
				const localized = this.isoToLocalized(this._innerValue);
				this.inputModel = localized;
				this.datePickerModel = this.isoToNgbDate(this._innerValue);
				this.validate(null);
			} else {
				this.inputModel = null;
				this.datePickerModel = null;
				this.validate(null);
			}

		}
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	updateSettings(settings: CustomizationSettings) {
		this.locale = settings.locale;
		this.datePipe = new DatePipe(settings.locale.identifier);
		this.inputModel = this.isoToLocalized(this._innerValue);
	}

	constructor(
		private customizationService: CoreCustomizationService,
		private coreLocaleLocalizationService: CoreLocaleLocalizationService
	) {
		this.updateSettings(this.customizationService.getSettings());
		this.markDisabled = this.markDisabled.bind(this);
	}

	markDisabled(date: NgbDate, current: { year: number, month: number }): boolean {
		return date.month !== current.month || this.isWithinInactivePeriod(date) || this.isWithinPublicHolidays(date);
	}

	isWithinInactivePeriod(date: NgbDate): boolean {
		if (this.inActiveFrom && this.inActiveBefore) {
			return date.equals(this.isoToNgbDate(this.inActiveFrom)) ||
				(date.after(this.isoToNgbDate(this.inActiveFrom)) &&
					date.before(this.isoToNgbDate(this.inActiveBefore)));
		}
		if (this.inActiveFrom && !this.inActiveBefore) {
			return date.equals(this.isoToNgbDate(this.inActiveFrom)) ||
				date.after(this.isoToNgbDate(this.inActiveFrom))
		}
		if (!this.inActiveFrom && this.inActiveBefore) {
			return date.before(this.isoToNgbDate(this.inActiveBefore))
		}
		return false;
	}

	isWithinPublicHolidays(date: NgbDate): boolean {
		let isOnPublicHoliday: boolean = false;
		if (!this.countryPublicHolidays || !this.countryPublicHolidays.length) {
			return isOnPublicHoliday;
		}
		for (var i = 0; i <= this.countryPublicHolidays.length; i++) {
			if (date.equals(this.isoToNgbDate(this.countryPublicHolidays[i]))) {
				isOnPublicHoliday = true;
				break;
			}
		}
		return isOnPublicHoliday;
	}

	getStartDate() {
		if (this.datePickerModel) {
			return { year: this.datePickerModel.year, month: this.datePickerModel.month };
		}
		return null;
	}

	ngOnInit() {
		this.subscriptions.push(this.customizationService.changed.subscribe(settings => this.updateSettings(settings)));
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
	}

}

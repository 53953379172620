
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreMapCoordinates } from './CoreMapCoordinates';
import { CoreMapService } from './CoreMapService';

@Component({
    selector: 'app-core-map-marker',
    template: ``
})
export class CoreMapMarkerComponent implements OnInit {

    private _latitude: number;
    private _longitude: number;
    private _position: any;
    private _title: string;
    private _iconUrl: string;
    private markerReady: boolean = false;
    private _options: { content: HTMLElement; };

    @Output()
    dragEnd: EventEmitter<CoreMapCoordinates> = new EventEmitter<CoreMapCoordinates>();

    @Output()
    markerClick: EventEmitter<CoreMapCoordinates> = new EventEmitter<CoreMapCoordinates>();

    @Input()
    set latitude(latitude: number) {
        this._latitude = latitude;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
    }

    get latitude(): number {
        return this._latitude;
    }

    @Input()
    set longitude(longitude: number) {
        this._longitude = longitude;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
    }

    get longitude(): number {
        return this._longitude;
    }

    @Input()
    set position(value: any) {
        this._position = value;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
    }

    get position(): any {
        return this._position;
    }

    @Input()
    set title(title: string) {
        this._title = title;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
    }

    get title(): string {
        return this._title;
    }

    @Input()
    set iconUrl(iconUrl: string) {
        this._iconUrl = iconUrl;
        if (this.markerReady) {
            this.coreMapService.sendToParent('markers');
        }
        const icon = document.createElement('img');
        icon.src = this.iconUrl;
        this._options = {
            content: icon
        };
    }

    get iconUrl(): string {
        return this._iconUrl;
    }

    public get content(): any {
        return this._options?.content;
    }

    public get options(): any {
        return this._options;
    }

    @Input() markerDraggable: boolean = false;

    constructor(
        private coreMapService: CoreMapService
    ) { }

    ngOnInit(): void {
        this.markerReady = true;
    }

    onDragEnd(event: any) {
        let coordinates = new CoreMapCoordinates();
        coordinates.latitude = event.latLng.toJSON().lat;
        coordinates.longitude = event.latLng.toJSON().lng;
        this.dragEnd.emit(coordinates);
    }

    onMarkerClick() {
        let coordinates = new CoreMapCoordinates();
        coordinates.latitude = this.latitude;
        coordinates.longitude = this.longitude;
        this.markerClick.emit(coordinates);
    }

} 

import { AfterViewInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { CoreMapPolylinePointComponent } from './CoreMapPolylinePointComponent';

declare const BMap: any;

class Point {
    lng: number;
    lat: number;
}

@Component({
    selector: 'app-core-map-polyline',
    template: ``
})
export class CoreMapPolylineComponent implements AfterViewInit {

    @ContentChildren(CoreMapPolylinePointComponent) points: QueryList<CoreMapPolylinePointComponent>;
    @Input() strokeColor: string;
    @Input() geodesic: boolean = false;

    public options: any;

    getPointsArray(): Array<Point> {
        let polylinePointsArray: Point[] = [];
        this.points.forEach(polylinePoint => {
            polylinePointsArray.push(new BMap.Point(polylinePoint.longitude, polylinePoint.latitude));
        });
        return polylinePointsArray;
    }

    ngAfterViewInit(): void {
        var _mapPoints = new Array<any>();
        this.points.forEach(point => {
            _mapPoints.push({ lat: point.latitude, lng: point.longitude });
        });
        this.options = {
            path: _mapPoints,
            strokeColor: this.strokeColor,
            geodesic: this.geodesic
        };
    }
} 